<template>
  <v-container fluid v-if="classId">
    <DBView :classId="classId" />
  </v-container>
  <v-col v-else cols="11">
    <p>Please select a database.</p>
  </v-col>
</template>

<script>
import DBView from "@/components/database/data/DBView";

export default {
  components: {
    DBView
  },
  data() {
    return {};
  },
  watch: {
    classId: {
      immediate: true,
      handler() {}
    }
  },
  computed: {
    classId() {
      return this.$route.params.classId;
    }
  },
  methods: {}
};
</script>
